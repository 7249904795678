import { format as formatDate } from 'date-fns';
import { isDateExpression, getDateExpression } from './helpers.dateExpressions.ts';
const regexes = {
    beginBracket: /\[/g,
    apostrophe: /'/g,
    isodate: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?Z$/,
    jsondate: /^\/Date\((-?[0-9]+)\)\/$/i,
    uniqueidentifier: /^[a-z0-9]{8}-([a-z0-9]{4}-){3}[a-z0-9]{12}$/i,
    regexEscaper: /(\/|\.|\*|\+|\?|\||\(|\)|\[|\]|\{|\}|\\)/g
};
export function isBlankValueUsed(pValues: string | any[]) {
    if (pValues instanceof Array && pValues.length) {
        for (var i = 0; i < pValues.length; i++) {
            if (pValues[i] === null || pValues[i] == 0) {
                return true;
            }
            if (pValues[i].length === 0) {
                return true;
            }
        }
        return false;
    } else {
        return false;
    }
}

export function getContains(pColumn: string, pValue: any) {
    if (pValue && typeof pValue == 'string' && pValue.indexOf(" ") > 0) {
        let vReturn: Array<string> = [];
        let vColumn = safeWrapName(pColumn)
        pValue.split(" ").forEach((val: string) => {
            vReturn.push(vColumn + " LIKE '%" + safeEscapeValue(val) + "%'")
        })
        return "(" + vReturn.join(" AND ") + ")";
    }

    return safeWrapName(pColumn) + " LIKE '%" + safeEscapeValue(pValue) + "%'";
}

export function getTimeInSeconds(pDate: Date) {
    return (pDate.getHours() * 60 + pDate.getMinutes()) * 60 + pDate.getSeconds();
}
export function safeWrapName(pName: any) {
    if (pName.indexOf("ToStr(") > - 1) return pName;
    return "[" + pName.replace(regexes.beginBracket, "[[") + ']';
}

export function safeEscapeValue(pValue: any) {
    if (!pValue && pValue !== 0) return "";
    if (pValue === null) { return ""; }
    if (typeOf(pValue) === "datetime") { return "#" + formatDate(pValue, "yyyy-MM-dd", false, false) + "#"; }
    return pValue.toString().replace(regexes.apostrophe, "''");
}
export function safeWrapValue(pValue: any) {
    if (typeOf(pValue) === "datetime") {
        return (pValue === null ? "NULL" : safeEscapeValue(pValue));
    } else {
        if (typeof pValue === "number") {
            return (pValue === null ? "NULL" : "" + safeEscapeValue(pValue) + "");
        } else if (isDateExpression(pValue)) {
            return pValue;
        } else if (checkIfColumn(pValue)) {
            return (pValue === null ? "NULL" : safeEscapeValue(pValue));
        } else {
            return (pValue === null ? "NULL" : "'" + safeEscapeValue(pValue) + "'");
        }

    }
}
export function convertToTime(pValue: any) {
    return "CONVERT(time, " + pValue + ")";
}
export function safeWrapNameAndConvertToTime(pValue: any) {
    return convertToTime(safeWrapName(pValue));
}
export function safeWrapValueAndConvertToTime(pValue: any) {
    return convertToTime(safeWrapValue(pValue));
}
export function safeEscapeValueList(pValues: Array<any>, pSeparator: string) {
    return (pValues instanceof Array && pValues.length ? pValues.map(safeWrapValue).join(pSeparator) : pValues);
}

export function isNumbersInList(pValue: Array<any>) {
    var vIsNumber = true;
    pValue.forEach((value: any) => {
        if (typeof value == "string" && value.length && isNaN(parseFloat(value))) {
            vIsNumber = false;
        }
    })

    return vIsNumber;
}

export function parseInListNumber(pValue: any) {
    const vValue = parseFloat(pValue);
    if (isNaN(vValue)) {
        return '';
    }
    return vValue;
}

export function dateParser(val: any) {
    let vTmp: any;
    if (typeOf(val) === "datetime") { return val; }
    if (typeOf(val) === "string") {
        if (!val.indexOf("/Date(")) { return new Date(Number(val.substring(6, val.length - 2))); }
        if (regexes.isodate.test(val)) { return new Date(val); }
        if (isDateExpression(val)) {
            return getDateExpression(val);
        }
        vTmp = new Date(val);


        if (!isNaN(vTmp.valueOf())) { return vTmp; }
    }
    return null; // If all date checks fail, return null
};

export function typeOf(pValue: any) {
    if (pValue === null) {
        return "null";
    } else if (typeof pValue === "object") {
        if (pValue instanceof Array) { return "array"; }
        else if (pValue instanceof Date) { return "datetime"; }
        else if (pValue instanceof Number) { return "number"; }
    }
    return (typeof pValue);
}

export function returnFirstArgument(arg0: any) {
    return arg0;
}

export function checkIfColumn(value: string) {
    if (value === null || value === undefined) {
        return false;
    }
    if (typeof value === "string" && new RegExp(/^\[[a-zA-Z0-9\- ]+\]$/).test(value)) {
        return true;
    } else {
        return false;
    }
}

export function cleanColumnName(pColumn: string) {
    return pColumn.replace("ToStr(", "").replace(")", "");
}

function escapeFullTextString(str: string) {
    return `"${str.replaceAll('"', '""')}*"`
}
function escapeTextString(str:string) {
    return str.replaceAll('"', '""');
}

function splitAndEscapeFullTextString(str: string | string[], keyword: string) {

    if (Array.isArray(str)) {
        str = str.join(' ');  
    }

    if (typeof str !== 'string') {
        return
    }

    const words = str.split(' ').filter(s => s);
 
    return words.map(escapeFullTextString).join(` ${keyword} `);
}

export function searchTextWithoutLeadingZeroes(str: string | string[]){
    
    if (Array.isArray(str)) {
        str = str.join(' ');  
    }

    if (typeof str !== 'string') {
        return
    }

    const words = str.split(' ').filter(s => s);
    const withoutLeadingZeroes = words.filter(x=>!(isNumeric(x) && x.startsWith("0")));
  
    return withoutLeadingZeroes.map(escapeFullTextString).join(` AND `);
}

export function searchTextWithLeadingZeroes(str: string | string[]){
    if (Array.isArray(str)) {
        str = str.join(' ');  
    }

    if (typeof str !== 'string') {
        return
    }
    const words = str.split(' ').filter(s => s);
    const leadingZeroes = words.filter(x=>isNumeric(x) && x.startsWith("0"));

    return leadingZeroes.map(escapeTextString);

}

export function getFullTextSearchString(searchString: string) {
    // if ( this._currentOption == "contains") {
    //    return this.escapeFullTextString(searchString);
    // } else if ( this._currentOption == "contains_all") {
    return splitAndEscapeFullTextString(searchString, 'AND');
    //  } else if ( this._currentOption == "contains_any") {
    // return this.splitAndEscapeFullTextString(searchString, 'OR');
    // } else {
    // return searchString;
    // }
}

function isNumeric(str:any) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function getFullTextFilterString(pItem:any){
        const leadingZeroesStr = searchTextWithLeadingZeroes(pItem.value, );
        const noLeadingZeroesStr = searchTextWithoutLeadingZeroes(pItem.value);
        const vReturn =  `FULLTEXT_SEARCH(${pItem.fullText?.fnc},'${noLeadingZeroesStr}',${pItem.column})`;
        if(leadingZeroesStr?.length && !noLeadingZeroesStr){
            return generateLeadingZeroesString(leadingZeroesStr,pItem.column);
        }
        if(leadingZeroesStr?.length){
            return `(${vReturn} AND ${generateLeadingZeroesString(leadingZeroesStr,pItem.column)})`
       
        }

        return vReturn;
       
    }

function generateLeadingZeroesString(pString:Array<string>,pColumn:string){
    return pString.map(x=> `[${pColumn}] LIKE '%${x}%'`).join(" AND ");
}
